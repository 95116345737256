.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: rgb(0, 124, 203);
  height: 11em;
  padding: 20px;
  color: rgb(255, 245, 113);
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#homepage {
  margin: 1em auto;
  min-height: 100%;
  min-width: 100%;
}

#leftlist:hover {
  cursor: pointer;
}

#dishTitle {
  display: none;
}

.dishlist {
  height: 8em;
  line-height: 4em;
  text-align: center;
  margin: 2em;
  margin-top: 0;
  font-size: 18px;
}

@media only screen and (max-width: 770px) {
  .dishlist {
    font-size: 13px;
  }
}

@media only screen and (max-width: 770px) {
  #dishTitle {
    display: block;
  }
}

@media only screen and (max-width: 770px) {
  #leftlist {
    display: none;
  }
}

.welcomeMessage {
  min-width: 100%;
  border: 1px solid black;
  height: 20em;
}

.cta:hover {
  text-decoration: none;
}

.welcomeMessage h1 {

  text-align: center;
  margin-top: 1em;
}

.blackpeppercrab {
  background: url("./images/bcrab.jpg") center no-repeat;
  background-size: cover;
  border: solid 1px black;
  height: 20em;
}

.crabPicture {
  background: url("./images/crab.jpg") center no-repeat;
  background-size: cover;
  border: solid 1px black;
  height: 20em;
}

.spinach {
  background: url("./images/storefront.jpg") center no-repeat;
  background-size: cover;
  border: solid 1px black;
  height: 20em;
}

.crabPicture h3 {
  vertical-align: middle;
  text-align: center;
  line-height: 100%;
}

.row {
margin: 3em auto;
}

.menu-main {
  margin: 0 auto;
  margin-top: 18vh;
  width: 75%;
}

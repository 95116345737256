.pdfObject {
    padding: 1em;
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: #2d2c2c8a;
    margin-top: 3em;
}

.closedModal {
    right: 5px;
    color: white;
    font-size: x-large;
    margin-left: 90%;
}

.closedModal:hover {
    cursor: pointer;
}

.menuBtn {
    padding: 1em;
    font-size: x-large;
    border: 2px #777b77 solid;
    margin: 0.5em;
    border-radius: 0.2em;
}

.menuBtn:hover {
    padding: 1em;
    font-size: x-large;
    border: 2px #09a06b solid;
    margin: 0.5em;
    border-radius: 0.2em;
}

.menuLink {
    color: black;
    text-decoration: none;
}

.menuLink:hover {
    color: #09a06b;
    text-decoration: none;
}